import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useAnalytics } from 'use-analytics';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { ButtonGroup } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Protocol from './Protocol';
import CreateProtocolForm from './CreateProtocolForm';
import CreateFormulationForm from './Formulation/CreateFormulationForm';

import { openAppSnackbarNotification } from '../../../../services/snackbar-notifications/actions';

import PROTOCOL_TYPES from './constants';
import {
  componentNames,
  TRACK_NEW_FORMULATION,
  TRACK_NEW_PROTOCOL, TRACK_NEW_PROTOCOL_LINK,
} from '../../../../analytics/constants';

const DELETE_PROTOCOL = gql`
  mutation DeleteTableProtocol($id: ID!) {
    deleteTableProtocol(id: $id)
  }
`;

const DELETE_FORMULATION = gql`
  mutation DeleteFormulationTableProtocol($id: ID!) {
    deleteFormulationTableProtocol(id: $id)
  }
`;

const UPDATE_TABLE = gql`
  mutation UpdateTable($id: ID!, $hash: String!, $data: UpdateTableInput!) {
    updateTable(id: $id, hash: $hash, data: $data) {
      id
      hash
      tableProtocols {
        id
        hash
        title
        creator {
          id
          name
        }
        dateCreated
        dateUpdated
      }
    }
  }
`;

const PROTOCOLS_TYPES = {
  PROTOCOL: 'PROTOCOL',
  FORMULATION: 'FORMULATION',
};

const StructureTab = ({ table, readOnly, onRefetch }) => {
  const [createProtocolOpen, setCreateProtocolOpen] = useState(false);
  const [createFormulationOpen, setCreateFormulationOpen] = useState(false);
  const [type, setType] = useState(PROTOCOLS_TYPES.PROTOCOL);
  const [anchorEl, setAnchorEl] = useState(null);

  const [updateTable] = useMutation(UPDATE_TABLE);
  const [deleteTableProtocol] = useMutation(DELETE_PROTOCOL);
  const [deleteTableFormulation] = useMutation(DELETE_FORMULATION);

  const dispatch = useDispatch();

  const { track } = useAnalytics();

  const handleDragEnd = useCallback(async (ev) => {
    const toIndex = ev.destination?.index;
    const fromIndex = ev.source.index;

    if(toIndex == null || toIndex === fromIndex) return;

    const _tableProtocols = {
      ids: [],
      byId: {}
    };

    for(const protocol of table.tableProtocols) {
      _tableProtocols.ids.push(protocol.id);
      _tableProtocols.byId[protocol.id] = protocol;
    }

    _tableProtocols.ids.splice(toIndex, 0, _tableProtocols.ids.splice(fromIndex, 1)[0]);

    try {
      await updateTable({
        variables: {
          id: table.id,
          hash: table.hash,
          data: {
            tableProtocolIds: _tableProtocols.ids
          }
        },
        optimisticResponse: {
          updateTable: {
            __typename: 'Table',
            id: table.id,
            hash: 'temp-hash',
            tableProtocols: _tableProtocols.ids.map(id => _tableProtocols.byId[id])
          }
        }
      });
    } catch(e) {
      console.error(e);
    }
  }, [table, updateTable]);

  const handleDeleteProtocol = useCallback(async (id, type) => {
    try {
      const query = type === PROTOCOL_TYPES.PROTOCOL ? deleteTableProtocol : deleteTableFormulation;

      await query({
        variables: { id }
      });

      dispatch(openAppSnackbarNotification({
        message: 'The protocol has been deleted',
        variant: 'INFO'
      }));

      onRefetch();
    } catch(e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      }));
    }
  }, [deleteTableProtocol, deleteTableFormulation, dispatch, onRefetch]);

  const handleCreateProtocolOpen = useCallback(() => {
    track(TRACK_NEW_PROTOCOL.click, {
      component: componentNames.TABLE_STRUCTURE,
    });

    if (type === PROTOCOLS_TYPES.PROTOCOL) {
      setCreateProtocolOpen(true);
    } else {
      setCreateFormulationOpen(true);
    }
  }, [track, type]);

  const handleCreateProtocolClose = useCallback(() => {
    setCreateProtocolOpen(false);
  }, []);

  const handleCreateProtocolSuccess = useCallback(() => {
    setCreateProtocolOpen(false);
    onRefetch();
  }, [onRefetch]);

  const handleCreateFormulationClose = useCallback(() => {
    setCreateFormulationOpen(false);
  }, []);

  const handleCreateFormulationSuccess = useCallback(() => {
    track(TRACK_NEW_FORMULATION.save, {
      component: componentNames.TABLE_STRUCTURE,
    });

    setCreateFormulationOpen(false);
    onRefetch();
  }, [onRefetch, track]);

  const handleProtocolUpdated = useCallback(() => {
    onRefetch();
  }, [onRefetch]);

  const handleToggle = useCallback((e) => {
    setAnchorEl(el => el ? null : e.currentTarget);
  }, []);

  const handleMenuItemClick = useCallback((value) => {
    if (value === PROTOCOLS_TYPES.PROTOCOL){
      track(TRACK_NEW_PROTOCOL_LINK.click, {
        component: componentNames.TABLE_STRUCTURE,
      });
    } else {
      track(TRACK_NEW_FORMULATION.click, {
        component: componentNames.TABLE_STRUCTURE,
      });
    }

    setType(value);
    handleToggle();
  }, [handleToggle, track]);

  return (
    <Box pt="16px">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={table.id}>
          {provided => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {table.tableProtocols.map((protocol, i) => (
                <Protocol
                  key={protocol.id}
                  index={i}
                  data={protocol}
                  table={table}
                  readOnly={readOnly}
                  onDelete={handleDeleteProtocol}
                  onUpdated={handleProtocolUpdated}
                />
              ))}

              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      {createProtocolOpen ?
        <CreateProtocolForm
          tableId={table.id}
          protocols={table.tableProtocols}
          onClose={handleCreateProtocolClose}
          onSuccess={handleCreateProtocolSuccess}
        /> :
        null
      }

      {createFormulationOpen ?
        <CreateFormulationForm
          tableId={table.id}
          protocols={table.tableProtocols}
          onClose={handleCreateFormulationClose}
          onSuccess={handleCreateFormulationSuccess}
        /> :
        null
      }

      {readOnly ?
        null :
        <ButtonGroup
          variant="contained"
          sx={{
            marginTop: '15px',
            borderRadius: '4px'
          }}
          disabled={createProtocolOpen || createFormulationOpen}
        >
          <Button
            onClick={handleCreateProtocolOpen}
            sx={{
              textTransform: 'capitalize',
              borderRadius: '4px'
            }}
          >
            Add {type.toLowerCase()}
          </Button>

          <Button
            size="small"
            onClick={handleToggle}
            sx={{
              borderRadius: '4px'
            }}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      }

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleToggle}
      >
        <MenuItem
          onClick={() => handleMenuItemClick(PROTOCOLS_TYPES.PROTOCOL)}
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {PROTOCOLS_TYPES.PROTOCOL.toLowerCase()}
        </MenuItem>

        <MenuItem
          onClick={() => handleMenuItemClick(PROTOCOLS_TYPES.FORMULATION)}
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {PROTOCOLS_TYPES.FORMULATION.toLowerCase()}
        </MenuItem>
      </Menu>
    </Box>
  );
};

StructureTab.propTypes = {
  table: PT.object,
  readOnly: PT.bool,
  onRefetch: PT.func
};

export default StructureTab;
