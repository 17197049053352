import React, { useCallback, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import FormulationForm from '../FormulationForm';
import ConfirmationDialog from '../../Protocol/ConfirmationDialog';

import { openAppSnackbarNotification } from '../../../../../../services/snackbar-notifications/actions';

import UPDATE_FORMULATION from './gql';

const EditFormulationForm = ({ initialData, readOnly, onSuccess, onCancel }) => {
  const [updateTableFormulation, { loading, error }] = useMutation(UPDATE_FORMULATION);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const pendingData = useRef();

  const dispatch = useDispatch();

  const handleUpdate = useCallback(async (data) => {
    try {
      await updateTableFormulation({
        variables: {
          id: initialData.id,
          hash: initialData.hash,
          data
        }
      });

      dispatch(openAppSnackbarNotification({
        message: 'The protocol has been updated',
        variant: 'INFO'
      }));

      onSuccess();
    } catch(e) {
      console.error(e);
    }
  }, [dispatch, initialData.hash, initialData.id, onSuccess, updateTableFormulation]);

  const handleSubmit = useCallback((data) => {
    pendingData.current = data;
    setConfirmationDialogOpen(true);
  }, []);

  const handleConfirmationDialogClose = useCallback(() => {
    setConfirmationDialogOpen(false);
  }, []);

  const handleConfirmationDialogSubmit = useCallback(() => {
    handleUpdate(pendingData.current);
    pendingData.current = null;
    setConfirmationDialogOpen(false);
  }, [handleUpdate]);

  return (
    <Box>
      <FormulationForm
        initialData={initialData}
        readOnly={readOnly}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        loading={loading}
        error={error}
      />

      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onSubmit={handleConfirmationDialogSubmit}
      />
    </Box>
  );
};

EditFormulationForm.propTypes = {
  initialData: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default EditFormulationForm;
