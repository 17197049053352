import { gql } from '@apollo/client';

export const VIEWER_ID = gql`
  query ViewerOwner {
    viewer {
      id
    }
  }
`;

export const GET_ITEMS = gql`
  query TabularItems(
    $input: TableItemsQueryInput,
    $after: String,
    $first: Int,
  ) {
    tableItems(
      input: $input
      after: $after,
      first: $first
    ) {
      columnOptions {
        featureId
        notInCurrentFilterResult
        protocolTitle
        protocolType
        title
        protocolGroup {
          id
        }
      }
      itemsTable {
        edges {
          cursor
          node {
            item {
              id
              code
              title
              description
              dateCreated
              dateUpdated
              dateFabricated
              interactions {
                comments
              }
              creator {
                id
                name
                ... on CurrentUser {
                  userId
                  avatarColor
                  pictureDownloadURL
                }
              }
              table {
                id
                title
                viewerMaxRole
                tableParameters {
                  id
                  featureId
                  titleTableItem {
                    id
                    title
                  }
                  valueType
                  tableProtocol {
                    id
                    type

                    ...on TableProtocolFormulation {
                      unit {
                        id
                        name
                      }
                    }
                  }
                }
              }
              numberOfTableItemFiles
              isUsedInProtocols
            },
            values {
              featureId
              text
              quantity
              boolean
              linkedTableItem {
                id
                code
                title
                viewerMaxRole
              }
              tableItemId
              tableParameterId
              type
              calculationError
            }
          }
        }
        columns {
          featureId
          protocolTitle
          protocolType
          protocolGroup {
            id

            ...on ProtocolGroupFormulation {
              includedProtocols {
                id
                tableId
              }
            }
          }
          title
          valueType
          titleLinkId
          unitName
          titleLinkIsAccessible
          notInCurrentFilterResult
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
        }
        filterInfo {
          totalNumberOfFilteredItems
          totalNumberOfItems
        }
      }
      filterOptions {
        createdTimestamp {
          from
          to
        }
        creators {
          number
          userId
          userName
          firstName
          lastName
        }
        tables {
          id
          title
          number
          parentFolderId
          isFolder
        }
      }
      tableItemQueryId
    }
  }
`;

export const GET_TABLES = gql`
  query Tables {
    tables {
      id
      hash
      title
      description
      viewerMaxRole
      tableProtocols {
        id
      }
      tableParameters {
        featureId
        id
        calculation {
          contents {
            type
            children {
              id
              text
              type
              children {
                text
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FOLDERS = gql`
  query Folders {
    folders {
      id
      title
      viewerMaxRole
      childFolders {
        id
      }
      childTables {
        id
      }
    }
  }
`;
