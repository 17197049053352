import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import ProtocolContainer from './ProtocolContainer';
import SingleProtocol from './SingleProtocol';
import FormulationProtocol from './FormulationProtocol';

import ContainerStyled from './styles';

import toProtocols from './services/toProtocols';
import { isRoleEqualOrHigher, rolesIds } from '../../../../utils/roles';
import useExpandList from '../hooks/useExpandList';

const SIDEBAR_SHOW_EMPTY_STATUS = 'SIDEBAR_SHOW_EMPTY_STATUS';

const hasValue = ({ link, quantity, text, boolean }) => [
  link,
  typeof quantity === 'number',
  text,
  typeof boolean === 'boolean'
].some(Boolean);

const ProtocolsTab = ({
  id,
  activeProtocolId,
  maxRole,
  table,
  values,
  onAddFormulationValue
}) => {
  const data = toProtocols({ table, values });

  const [showEmptyValues, setShowEmptyValues] = useState(() => {
    return JSON.parse(localStorage.getItem(SIDEBAR_SHOW_EMPTY_STATUS)) || false;
  });

  const filteredProtocols = useMemo(() => {
    const result = [];

    for(const protocol of data) {
      const _protocol = { ...protocol };

      if(_protocol.type === 'FORMULATION') {
        _protocol.filteredFeatures = protocol.features?.filter(hasValue) ?? [];

        result.push(_protocol);
      } else if(showEmptyValues) {
        _protocol.filteredFeatures = Array.prototype.concat(protocol.features ?? []);

        result.push(_protocol);
      } else {
        _protocol.filteredFeatures = protocol.features?.filter(hasValue) ?? [];

        if(_protocol.filteredFeatures.length)
          result.push(_protocol);
      }
    }

    return result;
  }, [data, showEmptyValues]);

  const listWithoutEmptyFeatures = useMemo(() => {
    return filteredProtocols.filter(protocol => protocol.features.length);
  }, [filteredProtocols]);

  const {
    isAllExpanded,
    handleToggleExpand,
    handleToggleSingleObject,
    expandedList
  } = useExpandList(listWithoutEmptyFeatures, activeProtocolId);

  const handleShowEmptyValues = useCallback(() => {
    localStorage.setItem(SIDEBAR_SHOW_EMPTY_STATUS, JSON.stringify(!showEmptyValues));

    setShowEmptyValues(state => !state);
  }, [showEmptyValues]);

  const readOnly = !isRoleEqualOrHigher(maxRole, rolesIds.WRITE);

  return (
    <ContainerStyled>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={showEmptyValues}
              onChange={handleShowEmptyValues}
            />
          }
          label={
            <Typography
              sx={{
                fontSize: '12px'
              }}
            >
              Show empty values
            </Typography>
          }
        />

        {filteredProtocols.length ?
          <Tooltip
            title={isAllExpanded ? 'Collapse All' : 'Expand All'}
            placement="top"
          >
            <IconButton
              onClick={handleToggleExpand}
              sx={{
                '&:hover': {
                  backgroundColor: 'white'
                }
              }}
            >
              {isAllExpanded ?
                <UnfoldLessIcon
                  sx={{
                    fontSize: '14px'
                  }}
                />
                :
                <UnfoldMoreIcon
                  sx={{
                    fontSize: '14px'
                  }}
                />
              }
            </IconButton>
          </Tooltip> :
          null
        }
      </Box>

      {filteredProtocols.map(object => (
        <ProtocolContainer
          key={object.id}
          id={object.id}
          open={expandedList.includes(object.id)}
          disableExpand={object.type !== 'FORMULATION' && !object.filteredFeatures.length}
          data={object}
          onClose={handleToggleSingleObject}
        >
          {object.type === 'PROTOCOL' ?
            <SingleProtocol
              id={id}
              data={object}
              showEmptyValues={showEmptyValues}
              readOnly={readOnly}
            /> :
            <FormulationProtocol
              itemId={id}
              data={object}
              readOnly={readOnly}
              onAddFormulationValue={onAddFormulationValue}
            />
          }
        </ProtocolContainer>
      ))}
    </ContainerStyled>
  );
};

ProtocolsTab.propTypes = {
  id: PropTypes.string,
  activeProtocolId: PropTypes.string,
  maxRole: PropTypes.string,
  table: PropTypes.object,
  values: PropTypes.array,
  onAddFormulationValue: PropTypes.func
};

export default ProtocolsTab;
