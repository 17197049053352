import { gql } from '@apollo/client';

export const UPDATE_FEATURE_VALUE = gql`
  mutation UpdateFeatureValue($input: UpdateTableValueInput!) {
    updateTableValue(input: $input) {
      dependentTableValues {
        tableParameterId
        type
        tableItemId
        quantity
        text
        boolean
        linkedTableItem {
          id
          code
          title
          viewerMaxRole
        }
        calculationError
      }
      tableValue {
        tableParameterId
        featureId
        type
        tableItemId
        quantity
        text
        boolean
        linkedTableItem {
          id
          code
          title
          viewerMaxRole
        }
        calculationError
      }
    }
  }
`;
