import without from 'lodash/without';

const getFiltersWithoutExtraData = (oldFilters, tablesData, itemsSorting, displayedColumns) => {
  const oldTables = oldFilters.input.filters.tableIds;

  const activeTables = tablesData.filter(table => oldTables?.includes(table.id));

  let input = {
    ...oldFilters.input,
    tableHeaderFeatureIDs: undefined,
    filters: {}
  };

  let tableHeaderProtocolGroupIDs = [];

  if (activeTables.length) {
    if(oldFilters.input.tableHeaderProtocolGroupIDs?.length) {
      const excludeGroupIds = [];

      for(const column of displayedColumns) {
        if(column.protocolGroup?.includedProtocols) {
          const tableIds = column.protocolGroup.includedProtocols.map(({ tableId }) => tableId);

          if(!tableIds.some(({ tableId }) => oldTables.includes(tableId))) {
            excludeGroupIds.push(column.protocolGroup.id);
          }
        }
      }

      tableHeaderProtocolGroupIDs = without(oldFilters.tableHeaderProtocolGroupIDs, excludeGroupIds);
    }

    const activeTablesFeatures = activeTables
      .reduce((prev, curr) => {
        const ids = curr.tableParameters || [];
        return [...prev, ...ids];
      }, []);

    const featureSliceBys = oldFilters.input.filters?.featureSliceByList?.featureSliceBys
      .filter(feature => activeTablesFeatures?.includes(feature.featureId)) || [];

    const tableHeaderFeatureIDs = oldFilters.input.tableHeaderFeatureIDs?.filter(id => activeTablesFeatures.includes(id));

    input = {
      ...oldFilters.input,
      tableHeaderFeatureIDs: tableHeaderFeatureIDs?.length ? tableHeaderFeatureIDs : undefined,
      tableHeaderProtocolGroupIDs: tableHeaderProtocolGroupIDs.length ? tableHeaderProtocolGroupIDs : undefined,
      filters: {
        ...oldFilters.input.filters,
        featureSliceByList: {
          featureSliceBys
        }
      }
    };
  }

  if(input.sort?.featureId && !input.tableHeaderFeatureIDs?.includes(input.sort.featureId)) {
    input.sort = itemsSorting.sortItems({ param: 'DATE_UPDATED', order: 'DESC' });
  }

  return input;
};

export default getFiltersWithoutExtraData;
