import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

import { SubmitButtonStyled, CancelButtonStyled } from './styles';

const EditButtons = ({ disableSubmit, loading, onCancel, onSubmit }) => {
  return (
    <Box sx={{ display: 'flex', gap: '4px' }}>
      <SubmitButtonStyled
        disabled={disableSubmit || loading}
        onClick={onSubmit}
      >
        <DoneIcon />
      </SubmitButtonStyled>

      <CancelButtonStyled
        disabled={loading}
        onClick={onCancel}
      >
        <CloseIcon />
      </CancelButtonStyled>
    </Box>
  );
};

EditButtons.propTypes = {
  disableSubmit: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default EditButtons;
