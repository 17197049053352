import { gql } from '@apollo/client';

import { tableValueFragment } from '../../components/ItemView/gql/get-item';

export const UPDATE_FORMULATION_VALUE = gql`
  ${tableValueFragment}
  mutation UpdateFormulationValue($itemId: ID!, $parameterId: ID!, $value: Float) {
    updateFormulationValue(tableItemId: $itemId, tableParameterId: $parameterId, value: $value) {
      dependentTableValues {
        ...TableValueFragment
      }
      tableValue {
        ...TableValueFragment
      }
    }
  }
`;

