import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useAnalytics } from 'use-analytics';

import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';

import ActionsBlock from '../ItemView/Header/ActionsBlock';
import ItemView from '../ItemView';

import { OPEN_STATE } from '../ItemView/constants';

import { UPDATE_ITEM_VIEWS_COUNT } from './services';
import { GET_ITEM } from '../ItemView/gql';

import { DIALOG_BREAKPOINT } from '../../styles';
import { componentNames, TRACK_SIDEBAR } from '../../analytics/constants';
import { rolesIds } from '../../utils/roles';

const SIDEBAR_WIDTH = {
  FULLSCREEN: '100vw',
  MINOR: '600px'
};

const ItemSidebar = ({
  itemId,
  defaultTab,
  onClose,
  onRemove,
  clickSource,
  search,
  readOnly,
  onAddFormulationValue
}) => {

  const { track } = useAnalytics();

  const [openState, setOpenState] = useState(OPEN_STATE.MINOR);

  const [updateViews] = useMutation(UPDATE_ITEM_VIEWS_COUNT);

  const { data, updateQuery, loading, refetch } = useQuery(GET_ITEM, {
    skip: !itemId,
    variables: { itemId },
    fetchPolicy: 'cache-and-network',
  });

  const itemData = useMemo(() => {
    if (readOnly && data?.tableItem)
      return {
        ...data?.tableItem,
        table: {
          ...data?.tableItem.table,
          viewerMaxRole: rolesIds.READ
        }
      };

    return data?.tableItem;
  }, [data?.tableItem, readOnly]);

  useEffect(() => {
    if (itemId){
      setOpenState(OPEN_STATE.MINOR);
    }
  }, [itemId, updateQuery, updateViews]);

  useEffect(() => {
    const updateItemViews = async () => {
      try {
        await updateViews({
          variables: {
            id: itemId
          }
        });

        updateQuery(state => ({
          ...state,
          tableItem: {
            ...state.tableItem,
            interactions: {
              ...state.tableItem.interactions,
              views: state.tableItem.interactions.views + 1
            }
          }
        }));
      } catch (e) {
        console.error(e);
      }
    };

    if (itemId && !loading){
      updateItemViews();
    }
  }, [itemId, loading, updateQuery, updateViews]);

  useEffect(() => {
    if (itemId && !loading && clickSource){
      track(TRACK_SIDEBAR.open, {
        component: componentNames.SIDEBAR,
        additional_info: {
          ai_item_id: itemData.code,
          ai_item_name: itemData.title,
          ai_source: clickSource
        }
      });
    }
  }, [clickSource, itemData?.code, itemData?.title, itemId, loading, track]);

  const handleClose = useCallback(() => {
    setOpenState(OPEN_STATE.MINOR);
    onClose?.();
  }, [onClose]);

  const handleItemRefetchRequest = useCallback(() => {
    refetch();
  }, [refetch]);

  const isFullscreen = openState === OPEN_STATE.FULLSCREEN;

  const isMobileView = useMediaQuery(`(${DIALOG_BREAKPOINT})`);

  const drawerWidth = useMemo(() => {
    if (isFullscreen || isMobileView){
      return SIDEBAR_WIDTH.FULLSCREEN;
    } else return SIDEBAR_WIDTH.MINOR;
  }, [isFullscreen, isMobileView]);

  if(!itemId)
    return null;

  const actions = () => <ActionsBlock
    onToggleFullScreen={setOpenState}
    onClose={handleClose}
    isFullscreen={isFullscreen}
    data={itemData}
    onRemove={onRemove}
    readOnly={readOnly}
  />;

  return (
    <Drawer
      key={itemId}
      anchor="right"
      open
      sx={{
        zIndex: 4,
        '& .MuiDrawer-paper': {
          top: '64px',
          boxShadow: '-5px 4px 24px 0px rgba(0, 0, 0, 0.15)',
          height: 'calc(100% - 64px)',
          overflowX: 'hidden',
          width: drawerWidth
        },
      }}
      variant="persistent"
    >
      <ItemView
        itemData={itemData}
        openState={openState}
        id={itemId}
        actions={actions}
        onItemRefetchRequest={handleItemRefetchRequest}
        defaultTab={defaultTab}
        search={search}
        onAddFormulationValue={onAddFormulationValue}
      />
    </Drawer>
  );
};

ItemSidebar.propTypes = {
  onClose: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  itemId: PropTypes.string,
  defaultTab: PropTypes.string,
  clickSource: PropTypes.string,
  search: PropTypes.string,
  readOnly: PropTypes.bool,
  onAddFormulationValue: PropTypes.func
};

export default ItemSidebar;
