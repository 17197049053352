import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';

import { COLOR_PRIMARY, COLOR_WHITE } from '../../../../../styles';

export const SubmitButtonStyled = styled(IconButton)`
  height: 30px;
  width: 30px;
  border-radius: 4px;
  background-color: ${COLOR_PRIMARY};
  
  svg {
    ${({ disabled }) => !disabled ? `color: ${COLOR_WHITE}` : null}
  }
  
  &:hover {
    background-color: ${COLOR_PRIMARY};
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14);
  }
`;

export const CancelButtonStyled = styled(IconButton)`
  height: 30px;
  width: 30px;
  border-radius: 4px;
  
  &:hover {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14);
  }
`;

