import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import EditableValue from './EditableValue';
import FeatureTitle from './FeatureTitle';
import FeatureValue from './FeatureValue';

import { EditableBox, EditIconStyled } from './styles';

import { UPDATE_FEATURE_VALUE } from './services';
import { openAppSnackbarNotification } from '../../../../../services/snackbar-notifications/actions';

import client from '../../../../../services/apollo-client';
import { ITEM_ID_VALUE_TYPE } from './EditableValue/constants';
import { GET_LINKED_ITEMS, GET_ITEM } from '../../../gql';

const SingleFeature = ({ itemId, data, readOnly }) => {

  const dispatch = useDispatch();

  const [updateValue] = useMutation(UPDATE_FEATURE_VALUE, {
    // FIXME: `TableItem.values` should return an array of `TableValue` types with empty fields
    refetchQueries: [GET_ITEM]
  });

  const [isValueEditing, setIsValueEditing] = useState(false);

  const handleClickValue = useCallback(() => {
    setIsValueEditing(state => !state);
  }, []);

  const handleSubmitValueEdit = useCallback(async (type, value) => {
    try {
      await updateValue({
        variables: {
          input: {
            tableItemId: itemId,
            tableParameterId: data.id,
            [type]: value,
          }
        },
        // TODO: Implement the optimistic response for this mutation
      });

      if (type === ITEM_ID_VALUE_TYPE){
        client.refetchQueries({
          include: [GET_LINKED_ITEMS]
        });
      }

      handleClickValue();
    } catch (e) {
      dispatch(
        openAppSnackbarNotification({
          variant: 'ERROR',
          message: e.message
        })
      );
    }
  }, [data.id, dispatch, handleClickValue, itemId, updateValue]);

  return (
    <TableRow
      sx={{
        maxWidth: '796px'
      }}
    >
      <TableCell
        align="left"
        sx={{
          padding: '2px 0',
          width: '50%'
        }}
      >
        <EditableBox
          readOnly={true}
          istitle="true"
        >
          <FeatureTitle
            data={data}
          />

          <EditIconStyled />
        </EditableBox>
      </TableCell>

      <TableCell
        align="left"
        sx={{
          padding: '2px 0',
          width: '50%'
        }}
      >
        {!isValueEditing ?
          <EditableBox
            onClick={!readOnly ? handleClickValue : null}
            readOnly={readOnly ?? undefined}
          >
            <FeatureValue data={data} />

            <EditIconStyled />
          </EditableBox>
          :
          <EditableValue
            onCancel={handleClickValue}
            onSubmit={handleSubmitValueEdit}
            data={data}
          />
        }
      </TableCell>
    </TableRow>
  );
};

SingleFeature.propTypes = {
  data: PropTypes.object.isRequired,
  itemId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default SingleFeature;
