import { serializeCalculation } from '../../../../../utils';

function toProtocols({ table = {}, values = [] }) {
  const toProtocolData = mapItem({ values });
  const mappedItems = (table.tableProtocols ?? []).map(toProtocolData);

  return mappedItems;
}

export default toProtocols;

function mapItem({ values }) {
  const valuesByParameterId = values.reduce((acc, i) => {
    acc[i.tableParameterId] = i;

    return acc;
  }, {});

  return i => ({
    id: i.id,
    type: i.type,
    title: i.title,
    description: i.description,
    dateCreated: i.dateCreated,
    dateUpdated: i.dateUpdated,
    username: i.creator.name,
    features: i.tableParameters?.map(parameter => {
      const valueData = valuesByParameterId[parameter.id];
      const linkedTableItem = valueData?.linkedTableItem;

      return {
        id: parameter.id,
        value: parameter.item?.id ?? parameter.title,
        item: parameter.titleTableItem,
        link: linkedTableItem,
        valueType: parameter.valueType,
        text: valueData?.text,
        quantity: valueData?.quantity,
        unit: parameter.unit,
        boolean: valueData?.boolean,
        calculationError: valueData?.calculationError,
        calculationFormula: parameter.calculation &&
          serializeCalculation(parameter.calculation.contents)
      };
    }),
    titleTables: i.titleTables,
    unit: i.unit
  });
}

