import React, { memo } from 'react';
import PT from 'prop-types';
// TODO: There was a problem that caused a significant rendering delay
// when many columns displayed in the table.
// The "hotfix" solution is to not render the component when it is not
// in the view. But the real solution should include further investigation
// of the issue and reducing the number of computation in each cell
// (e.g., user permissions, styles, etc.)
import { useInView } from 'react-intersection-observer';

import Box from '@mui/material/Box';

import TableCell from '../TableCell';

const FormulationValue = (props) => {
  const { ref, inView } = useInView();
  const ingredients = props.getValue();

  return (
    <TableCell ref={ref} value>
      {inView ?
        <Box
          display="flex"
          alignItems="center"
          gap="6px"
          height="100%"
          px="6px"
        >
          {ingredients.map(({ quantity, parameter }) => {
            const { titleTableItem, tableProtocol } = parameter;
            const ingredientName = titleTableItem.title;
            const unitName = tableProtocol.unit?.name;

            return (
              <Box
                key={ingredientName}
                p="3px 4px"
                borderRadius="6px"
                sx={{
                  background: 'var(--formulation-chips-color)'
                }}
                fontSize="12px"
                lineHeight="16px"
              >
                {quantity}{unitName} {ingredientName}
              </Box>
            );
          })}
        </Box>
        : null
      }
    </TableCell>
  );
};

FormulationValue.propTypes = {
  getValue: PT.func
};

export default memo(FormulationValue);
